<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="concepto_cuota"
      :rules="formRules.concepto_cuota"
    >
      <b10-autocomplete
        v-model="form.concepto_cuota"
        :items="conceptosCuota"
        item-text="descripcion_cuota"
        label="Concepto"
        clearable
        return-object
        :error-messages="errors"
        @change="changeConceptoCuota"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.inf_comercial"
      label="Información comercial"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="periodo_cuota"
      :rules="formRules.periodo_cuota"
    >
      <b10-autocomplete
        v-model="form.periodo_cuota"
        :items="periodosCuota"
        item-text="descripcion"
        label="Periodo"
        clearable
        return-object
        :error-messages="errors"
        @change="changePeriodoCuota"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="precio_periodo"
      :rules="formRules.precio_periodo"
    >
      <v-text-field
        v-model.number="form.precio_periodo"
        class="number-input"
        flat
        label="Precio por período"
        suffix="€"
        type="Number"
        clearable
        :error-messages="errors"
        messages="Impuestos no incluidos"
        @change="changePrecioPeriodo"
      />
    </ValidationProvider>
    <v-text-field
      v-model.number="form.dto"
      class="number-input"
      flat
      label="Descuento"
      type="Number"
      suffix="%"
    />
    <v-text-field
      v-model.number="form.unidades"
      class="number-input"
      flat
      label="Unidades"
      type="Number"
    />
    <v-text-field
      v-model.number="precioTotal"
      class="number-input"
      flat
      label="Importe total"
      suffix="€"
      type="Number"
      disabled
      messages="Impuestos no incluidos"
    />
    <v-checkbox
      v-model="form.opcional"
      label="Opcional"
    />
    <v-checkbox
      v-model="form.activacion_automatica"
      label="Activación automática"
      disabled
    />
    <v-text-field
      v-model.number="form.meses_permanencia"
      class="number-input"
      flat
      label="Meses de permanencia"
      type="Number"
      disabled
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './PresupuestoCuotaFormData'
import _ from '@/utils/lodash'
import { PARAMETRO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idpresupuestocli: {
      type: [Number, String],
      default: null,
    },
  },
  data () {
    return {
      form: {
        concepto_cuota: null,
        inf_comercial: null,
        periodo_cuota: null,
        precio_mes: null,
        precio_periodo: null,
        unidades: 1,
        dto: 0,
        opcional: false,
        activacion_automatica: null,
        meses_permanencia: null,
        dia_mes_limite: null,
        meses_desde_inst: null,
        dia_mes_cuota: null,
        meses_duracion: null,
        iva: null,
        recargo: null,
      },
      conceptosCuota: [],
      periodosCuota: [],
      presupuestocli: {},
      formRules: {
        concepto_cuota: { required: true },
        periodo_cuota: { required: true },
        precio_periodo: { required: true },
      },
    }
  },
  computed: {
    precioTotal () {
      return this.$online.presupuestoCuota.importeTotal(
        this.form.precio_mes,
        this.form.periodo_cuota?.meses,
        this.form.dto,
        this.form.unidades
      )
    }
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.IDPERIODO_CUOTA_POR_DEFECTO,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios, this.idpresupuestocli)
    this.conceptosCuota = resp.data.selectConceptoCuota.result.dataset
    this.periodosCuota = resp.data.selectPeriodoCuota.result.dataset
    this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
    const parametroPeriodoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDPERIODO_CUOTA_POR_DEFECTO })
    if (parametroPeriodoDefecto) {
      const existePeriodoDefecto = _.find(this.periodosCuota, { idperiodo_cuota: parseInt(parametroPeriodoDefecto.valor) })
      if (existePeriodoDefecto) {
        this.$set(this.form, 'periodo_cuota', existePeriodoDefecto)
        this.changePeriodoCuota(this.form.periodo_cuota)
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const lookupFormData = await Data.selectFormDataLookups(this, formData.idconcepto_cuota, formData.idperiodo_cuota)
      formData.concepto_cuota = lookupFormData.data.selectConceptoCuota.result.dataset[0]
      formData.periodo_cuota = lookupFormData.data.selectPeriodoCuota.result.dataset[0]
      this.form = formData
      this.changePeriodoCuota(formData.periodo_cuota)
    },
    changePeriodoCuota (value) {
      if (value && this.form.precio_periodo) {
        this.changePrecioPeriodo(this.form.precio_periodo)
      } else {
        this.$set(this.form, 'precio_mes', null)
        this.$set(this.form, 'precio_periodo', null)
      }
    },
    changePrecioPeriodo(value){
      if (value && this.form.periodo_cuota?.meses > 0) {
        this.$set(this.form, 'precio_mes',  value / this.form.periodo_cuota.meses)
      } else {
        this.$set(this.form, 'precio_mes',  null)
      }
    },
    changeConceptoCuota (value) {
      if (value) {
        this.$set(this.form, 'activacion_automatica', value.activacion_automatica)
        this.$set(this.form, 'inf_comercial', value.inf_comercial)
        this.$set(this.form, 'meses_permanencia', value.meses_permanencia)
        this.$set(this.form, 'dia_mes_limite', value.dia_mes_limite)
        this.$set(this.form, 'meses_desde_inst', value.meses_desde_inst)
        this.$set(this.form, 'dia_mes_cuota', value.dia_mes_cuota)
        this.$set(this.form, 'meses_duracion', value.meses_duracion)
        this.$set(this.form, 'precio_mes', value.precio_defecto)
        if (this.form.precio_mes && this.form.periodo_cuota?.meses > 0) {
          this.$set(this.form, 'precio_periodo', this.form.precio_mes * this.form.periodo_cuota.meses)
        } else {
          this.$set(this.form, 'precio_periodo', null)
        }
        if (this.presupuestocli.regimen_fiscal_con_iva) {
          this.$set(this.form, 'iva', value.valoriva)
        } else {
          this.$set(this.form, 'iva', 0)
        }
        if (this.presupuestocli.regimen_fiscal_con_recargo) {
          this.$set(this.form, 'recargo', value.valorrecargo)
        } else {
          this.$set(this.form, 'recargo', 0)
        }
      } else {
        this.$set(this.form, 'activacion_automatica', false)
        this.$set(this.form, 'inf_comercial', null)
        this.$set(this.form, 'meses_permanencia', null)
        this.$set(this.form, 'dia_mes_limite', null)
        this.$set(this.form, 'meses_desde_inst', null)
        this.$set(this.form, 'dia_mes_cuota', null)
        this.$set(this.form, 'meses_duracion', null)
        this.$set(this.form, 'precio_mes', null)
        this.$set(this.form, 'iva', null)
        this.$set(this.form, 'recargo', null)
      }
    },
  },
}
</script>

<style scoped>
.number-input >>> input {
  text-align: right;
}
</style>
